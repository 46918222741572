import React from 'react';
import { graphql } from 'gatsby';

// Types
import { TContentfulSection, THeadMeta, TBlogPost } from '../utils/types';

// Contentful
import SectionBuilder from '../contentful/SectionBuilder';

// Molecules
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';

// Sections
import BlogList from '../components/Sections/Blog/BlogList';

type TBlogPage = {
	data: {
		contentfulPage: {
			sections: TContentfulSection[]
		} & THeadMeta
		allContentfulBlogPost: {
			nodes: TBlogPost[]
		}
		allContentfulBlogTags: {
			nodes: { identifier: string }[]
		}
	}
	// eslint-disable-next-line react/no-unused-prop-types
	pageContext: {
		blogsPerPage: number
	}
}

const Blog: React.FC<TBlogPage> = (props) => {
	const {
		data: { contentfulPage, allContentfulBlogPost, allContentfulBlogTags },
		pageContext: { blogsPerPage },
	} = props;
	return (
		<main>
			{SectionBuilder(contentfulPage.sections)}
			<BlogList tags={allContentfulBlogTags.nodes} blogs={[...allContentfulBlogPost.nodes]} blogsPerPage={blogsPerPage} />
		</main>
	);
};

export default Blog;

export const Head: React.FC<TBlogPage> = (props) => {
	const { data: { contentfulPage } } = props;
	return <HeadMeta meta={contentfulPage.meta || {}} />;
};

export const pageQuery = graphql`
    query BlogQuery($slug: String!, $skip : Int, $limit: Int) {
        contentfulPage(slug: {eq: $slug}) {
            ...PageFields
        }
		allContentfulBlogPost(
			skip: $skip,
			limit: $limit,
			filter: {slug: {ne: "placeholder-blog"}},
			sort: {order: DESC, fields: publishedAt},
		) {
			nodes {
               ...BlogCard
            }
        }
		allContentfulBlogTags {
			nodes {
                identifier
            }
        }
    }
`;
