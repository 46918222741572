import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

// Types
import { TBlogPost } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

import { chunk } from '../../../utils/helpers';

// Molecules
import BlogCard from '../../Molecules/Cards/BlogCard';

// Organisms
import Section from 'fatcat-ui-library/components/Molecules/Section';
// import SearchInput from '../../Organisms/SearchInput/SearchInput';
import Pagination from '../../Organisms/Pagination/Pagination';

const BlogList: React.FC<{ blogs: TBlogPost[], blogsPerPage: number, tags: { identifier: string }[] }> = (props) => {
	const {
		blogs: allBlogs,
		blogsPerPage,
		// tags,
	} = props;
	const { search, pathname } = useLocation();

	const urlSearchParams = new URLSearchParams(search);

	// remove url param **page** if typed directly in search bar and value is one
	if (urlSearchParams.get('page') === '1') {
		urlSearchParams.delete('page');
		const newSearch = urlSearchParams.toString();
		navigate(newSearch ? `?${newSearch}` : pathname);
	}

	// get filterd query params
	const searchParams = urlSearchParams.get('search');
	const tagsParams = urlSearchParams.get('tags') || '';
	const currentPage = parseInt(urlSearchParams.get('page') || '1', 10);

	// filter blogs from query params
	const blogs = search ? allBlogs.filter((blog) => {
		const hasTitle = searchParams ? blog.title.toLowerCase().includes(searchParams) : true;
		const hasTag = tagsParams ? blog.tags && blog.tags.some(tag => tagsParams.includes(tag.identifier.toLowerCase())) : true;
		return hasTitle && hasTag;
	}) : allBlogs;

	// calculate number of pages
	const numberOfBlogPages = Math.ceil(blogs.length / blogsPerPage);

	return (
		<Section
			paddingBottom="s80"
			scale
		>
			{/* <SearchInput items={allBlogs.map(blog => blog.title)} tags={tags.map(tag => tag.identifier)} /> */}
			<Flex
				wrap
				gap="20px"
				padding={['b20', 't80']}
				largeTablet={['justifyContentCenter']}
				tablet={[{ gap: '24px', padding: ['t48'] }]}
			>
				{chunk(blogs, blogsPerPage).map((grp, index) => index + 1 === currentPage && grp.map((blog, index) => <BlogCard key={blog.title} {...blog} />))}
			</Flex>
			<Pagination currentPage={currentPage} numPages={numberOfBlogPages} />
		</Section>
	);
};

export default BlogList;
